:root {
    /* --page-zoom: 1.5 */
    --bg-gray-btn-hover: #f2f2f2;
    --theme-color-one: #10de89;
    /* --theme-color-two: #050e0a; */
    --bg-gray-btn-hover-dark: #d7d7d7;
    --text-gray: rgb(75, 75, 75);
    --bg-gray: #9a9a9a;
    --btn-theme-color-one-hover: #12d181;
}

body {
    zoom: 0.8;
    /* position: relative; */
}

.btn-primary {
    /* color: #ebebeb; */
    background-color: var(--theme-color-one);
    border: medium none;
    color: white;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 16px 15px 17px;
    text-transform: uppercase;
    cursor: pointer;
    /* transition: all 0.1s ease 0s; */
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.btn-primary:hover {
    background-color: var(--btn-theme-color-one-hover);
}

.btn-primary .active {}

.btn-primary .active:hover {
    /* Your styles here */
}


.btn-secondary {
    background-color: #f2f2f2;
    border: medium none;
    color: #000;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 16px 35px 17px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.1s ease 0s;
}

.btn-secondary:disabled:hover {
    /* Your styles here */
    background-color: var(--bg-gray-btn-hover);
    color: black;
}


.btn-secondary:hover {
    background-color: var(--bg-gray-btn-hover-dark);
}

.btn-secondary:hover {
    background-color: var(--theme-color-one);
    color: white;
}

.btn-small {
    min-width: 100px;
    height: 2.5rem;
}

.option-container {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
}

.variant-option {
    width: 2rem;
    height: 2rem;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: 0;
}

.variant-option--color {
    border: 1px solid lightgray;
    border-radius: 100px;
}

.variant-option--color-selected {
    border: 1px solid var(--theme-color-one);
}

.variant-option--selected {
    color: white;
    background-color: var(--theme-color-one);
}

@media (min-width: 1200px) {
    .hero-slider-one-custom-container>* {
        margin-top: -6rem;
    }

    .single-slider {
        height: 500px;
    }
}

@media (min-width: 1366px) {
    .hero-slider-one-custom-container>* {
        margin-top: -13rem;
    }

    /* 
    .single-slider {
        height: 500px;
    } */
}


/* Skeleton */
/* Variable Definitions */
:root {
    --base-color: #F4F4F4;
    --shine-color: rgba(229, 229, 229, 0.8);
    --animation-duration: 1.0s;
    --avatar-offset: calc(52px + 16px);
}

/* Mixin */
.background-gradient {
    background-image: linear-gradient(90deg, var(--base-color) 0px, var(--shine-color) 40px, var(--base-color) 80px);
    background-size: 600px;
}

.img-skeleton {
    width: 238px;
    height: 298px;
    background-color: #ccc;
    margin: 8px;
    background-image: linear-gradient(90deg, var(--base-color) 0px, var(--shine-color) 40px, var(--base-color) 80px);
    background-size: 600px;
    animation: shine-avatar var(--animation-duration) infinite ease-out;
}

.line {
    /* float: left; */
    width: 80%;
    height: 1.5rem;
    margin-top: 12px;
    border-radius: 5px;
    background-image: linear-gradient(90deg, var(--base-color) 0px, var(--shine-color) 40px, var(--base-color) 80px);
    background-size: 600px;
    animation: shine-lines var(--animation-duration) infinite ease-out;
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    40%,
    100% {
        background-position: 140px;
    }
}

@keyframes shine-avatar {
    0% {
        background-position: calc(-100px + var(--avatar-offset));
    }

    40%,
    100% {
        background-position: calc(400px + var(--avatar-offset));
    }
}

.product-skeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid black; */
}



.sidebar-blog-content:hover * {
    transition: all 0.1s;
    color: var(--theme-color-one)
}

.single-sidebar-blog-active * {
    color: var(--theme-color-one)
}


.btn-security-deposit-info {
    cursor: pointer;
    color: var(--theme-color-one);
}

.btn-hire-now {
    background-color: var(--theme-color-one);
    color: white;
    width: 10rem;
    height: 3.5rem;
    border: none;
    font-size: 1.1rem;
    outline: 0;
    transition: all 0.2s;
}

.sidebar-custom-height {
    max-height: 60vh;
    overflow-y: scroll;
}

.sidebar-custom-height::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.sidebar-custom-height::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    /* border-radius: 10px; */
    /* border: 0.5px solid lightgrey; */
    background-color: var(--bg-gray-btn-hover);
    cursor: pointer;
}

/* Handle */
.sidebar-custom-height::-webkit-scrollbar-thumb {
    background: lightgrey;
    /* border-radius: 10px; */
    transition: all 0.2s;
}

/* Handle on hover */
.sidebar-custom-height::-webkit-scrollbar-thumb:hover {
    background: var(--theme-color-one);
}

.btn-hire-now:hover {
    background-color: var(--bg-gray);
}

.product-page--extra-items-input {
    width: 2rem;
    height: 3.2rem;
    font-size: 1rem;
    background: white;
    background-color: white;
    outline: 0;
    padding-left: 0.7rem;
    /* border: 1px solid red; */
    border: none;
}

.product-page--extra-items-input:focus {
    background-color: white;
    background: white;
}

/* .product-page--extra-items-input:hover {
    width: 2rem;
    height: 3.2rem;
    font-size: 1rem;
    background: none;
    outline: 0;
    border: none;
} */

/* .product-page--add-extra-cart {
    background: white;
    color: gray;
    width: 2rem;
    height: 3.2rem;
    text-align: center;
    border: none;
    outline: none;
    border: 1px solid red;
}

.product-page--add-extra-cart {
    background: var(--bg-gray-btn-hover);
} */

.product-details-content .product-details-price {
    align-items: center;
    display: flex;
    margin: 15px 0 26px;
    margin-left: -1.9rem;
}

.btn-extras-quantity {
    background: none;
    color: gray;
    width: 1.5rem;
    height: 3.2rem;
    text-align: center;
    border: none;
    outline: 0;
}

.btn-extras-quantity:hover {
    color: var(--theme-color-one);
}

.product-page--add-extra-cart {
    background: none;
    color: gray;
    width: 2rem;
    height: 3.2rem;
    /* text-align: center; */
    border: none;
    outline: 0;
    padding-left: 0.55rem;
}

.product-page--add-extra-cart:hover {
    background-color: var(--bg-gray-btn-hover);
}

.product-page--add-extra-cart.active {
    background: var(--theme-color-one);
    color: white;
}



/* PRODUCT MODAL BACKDROP */
.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #0000003a;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
    zoom: 1.5;
}

.product-details-price-modal {
    align-items: center;
    display: flex;
    margin: 15px 0 26px;
    font-size: 1.5rem;
    margin-left: 50px;
}

.modal-body {
    border-radius: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}



.blog-details-img {
    position: relative;
}

.blog-details-content p {
    font-size: 1rem;
}

/* Google map over image */
.mapover-logo-stock-location {
    z-index: 10;
    position: absolute;
    top: -5%;
    left: 1%;
    width: 12rem;
    height: 12rem;
    background-color: white;
    border: 1px solid gray;
    border-radius: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mapover-logo-stock-location>img {
    /* border-radius: 10rem; */
    max-width: 9rem;
    /* height: inherit; */
    object-fit: contain;
}

.btn-info-modal {
    color: white;
    width: 8rem;
    height: 3rem;
    border: none;
    font-size: 1.1rem;
    outline: 0;
    transition: all 0.2s;
}

.btn-info-modal.accept {
    background-color: var(--theme-color-one);
}

.btn-info-modal.decline {
    background-color: var(--bg-gray);
}


/* Comments */
.comment-button {
    color: var(--bg-gray);
    cursor: pointer;
    transition: all 0.2s;
}

.comment-button:hover {
    color: var(--theme-color-one);
    cursor: pointer;
    transition: all 0.2s;
}

.error-text {
    font-size: 0.8rem;
    color: red;
}



/* Paginator */
.pro-pagination-style .page-indicator .active {
    background-color: var(--theme-color-one);
    color: white;
}

/* My Account -> Address Save or Delete Address buttons */
.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a,
.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete button {
    background: none;
    border: none;
    background-color: #000;
    color: #fff;
    display: inline-block;
    line-height: 1;
    margin: 0 2px;
    padding: 12px 15px;
    text-transform: uppercase;
    font-weight: 500;
}


/* HIRE BUTTON */
.hire-btn--out-of-stock {
    font-weight: bold;
    line-height: 1;
    z-index: 99;
    display: inline-block;
    padding: 23px 38px 23px;
    text-transform: uppercase;
    color: #fff;
    border: none;
    background: none;
    background-color: #343538;
}

.btn-hover .hire-btn--out-of-stock::before,
.btn-hover .hire-btn--out-of-stock::after,
.btn-hover .hire-btn--out-of-stock::before,
.btn-hover .hire-btn--out-of-stock::after {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.highlight-children>* {
    border: 1px solid red;
}

/* SIDEBAR WIDGET */
.sidebar-widget-list {
    /* height: 350px;
    overflow-x: auto; */
}

.custom-link {
    color: black;
    /* transition: all 0.2s; */
}


.cursor-pointer {
    cursor: pointer;
}

.product-badge-custom {
    width: 45px;
    height: 45px;
    border-radius: 100px;
    border: none;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.product-badge-custom.hire {
    /* background: darkgray; */
    background-color: rgb(42, 198, 255);
    color: white;
}

.product-badge-custom.discount {
    color: white;
    background: var(--theme-color-one);
}

@media (max-width: 767px) {

    .contact-page--contact-us {
        margin-top: 50px;
    }

}

.outline-transparent {
    outline: transparent;
}

.btn-remove-discount:hover {
    transition: all 0.2s;
    color: var(--theme-color-one);
}


/* message */
:root {
    --theme-color: #26c410;
}

.overlay-1 {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100000;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.466);
}

.container-1 {
    z-index: 101;
    width: 600px;
    height: 350px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 50px; */
    padding: 15px;
    overflow-y: auto;
}

.brand-icon {
    width: 200px;
}

.text-bold {
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.add-theme-color {
    color: var(--theme-color);
}

@media only screen and (max-width: 600px) {
    .container {
        width: calc(100% - 10px);
        height: calc(60% - 10px);
        overflow-y: auto;
        top: 0;
    }
}



/* BTN CONTAINER HIRE ROUND BUTTTONS */
.btn-container-hire {
    max-width: 350px;
    /* margin-left: 24px; */
    margin-left: 1.5rem;
    /* border: 1px solid red; */
}

@media only screen and (max-width: 1199px) {
    .btn-container-hire {
        /* max-width: 100%; */
        /* border: 1px solid red; */
        /* margin-left: 20px; */
        margin-left: 1rem;
    }
}

@media only screen and (max-width: 991px) {
    .btn-container-hire {
        /* max-width: 100%; */
        /* margin-left: 4px; */
        /* border: 1px solid green; */
        margin-left: 0.2rem;
    }
}

@media only screen and (max-width: 767px) {
    .btn-container-hire {
        /* max-width: 100%; */
        /* margin-left: 24px; */
        /* border: 1px solid blue; */
        margin-left: 1.5rem;
    }
}

@media only screen and (max-width: 400px) {
    .btn-container-hire {
        /* max-width: 100%; */
        /* border: 1px solid blue; */

        /* margin-left: 25px; */
        margin-left: 0rem;
    }
}

.grecaptcha-badge {
    opacity: 0;
}

.btn-banner {}

.btn-banner:hover {}

.btn-banner:active {}

.btn-show-more-less-prod-description {
    background: none;
    border: none;
    outline: none;
    text-decoration: underline;
    transition: all 0.1s;
}

.btn-show-more-less-prod-description:hover {
    color: var(--theme-color);
}

.hire-tag {
    background-color: var(--theme-color-one);
    color: white;
    padding: 5px 15px;
}

.container-title-checkout {
    background-color: var(--bg-gray-btn-hover);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.container-title-checkout:hover {
    cursor: pointer;
    background-color: var(--bg-gray-btn-hover-dark, 0.5);
}

.input-file-upload {
    background-color: rgba(248, 249, 250) !important;
    border: 0.5px solid lightgray;
    position: relative;
    height: 50px;
    margin-top: 10px;
}

.input-file-upload input {
    position: absolute;
    top: -15px;
    left: -1px;
    opacity: 0;
}

.input-file-upload p {
    color: grey;
}

/* Media query for screen width of 768px and above */
@media (min-width: 768px) {

    /* Your styles for screen width of 768px and above go here */
    .thanks-register-button {
        margin-bottom: 1rem;
    }
}

.cvv-message {
    position: absolute;
    background-color: rgb(42, 42, 42);
    width: 200px;
    height: 160px;
    cursor: pointer;
    top: -160px;
    padding: 10px 10px;
    color: white;
    text-align: center;
    font-size: 0.8rem;
    border-radius: 1px;
    z-index: 100;
}

.pointer {
    position: absolute;
    background-color: rgb(42, 42, 42);
    width: 12px;
    height: 12px;
    top: -6px;
    z-index: 100;
    /* transform: rotateY(-45deg); */
    transform: rotate(-45deg)
}

@media (max-width: 767px) {

    /* Your styles for screen width less than 768px go here */
    .cvv-message {

        left: calc(30px - 200px);
    }
}




/* CUSTOM HIRE BANNER */

.container-hire-dates {
    position: relative;
}

.container-hire-dates .date-range-picker-banner-container {
    position: absolute;
    top: 8px;
    left: 8px;
}

.container-hire-dates .date-range-picker-banner-container .react-minimal-datetime-range__range-input-wrapper {
    opacity: 0;
}

.date-range-picker-banner-container {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: flex-start;
    width: 105%;
    /* border: 1px solid red; */
}

@media (max-width: 500px) {
    .date-range-picker-banner-container {
        width: 100%;
    }
}


.container-decorated {
    background-color: rgba(235, 235, 235, 0.4);
    padding: 8px 10px;
}

.container-decorated .input-container {
    background-color: white;
    border: 1px solid rgb(183, 183, 183);
    border-radius: 4px;
}

.container-decorated .input-container input {
    background-color: white;
    border: none;
    /* border: 1px solid red; */
    outline: 1px solid transparent;
    transition: all 0s;
    width: calc(100% - 60px);
}

.text-theme-color {
    color: #12d181;
}

@media (max-width: 767px) {
    /* Your styles here */
}

@media (min-width: 768px) and (max-width: 991px) {
    /* Your styles here */
}

@media (min-width: 992px) and (max-width: 1199px) {
    /* Your styles here */
}

@media (min-width: 1200px) {
    /* Your styles here */
    /* .banner-hire {
        border: 1px solid red;
        height: 450px;
    } */
}

.link-hire-prod {
    text-decoration: underline;
    cursor: pointer;
}

.container-hire-price-banner {
    background-color: #3cbeaa;
    padding: 15px;
    width: 180px;
    border-radius: 4px;
}

.banner-hire {

}

.location-info p {
    line-height: 18px;
}

.location-info > div {
    width: 150px;
    border-right: 0.5px solid lightgray;
    margin-right: 1.5rem;
    padding-right: 1rem;
}

@media (max-width: 350px) {
    /* Your styles here */
    .location-info > div {
        width: 100%;
        border: none;
        margin-right: 1.5rem;
        padding-right: 1rem;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 0.5px solid lightgray;
    }
}

.location-info :last-child{
    border: none;
}




/* CART PAGE TABLE */
.img-container {
    position: relative;
}

.img-btn-overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 100;
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
}

.payment-auth {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 120px;
    z-index: 10000;
    background-color: white;
}

/* .product-tab-list a h4, .product-tab-list a .h4 .active {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    transition: all 0.3s ease 0s;
    color: red;
} */

.product-tab-list a.active h4, .product-tab-list a.active .h4 {
    color: #000;
    text-decoration: underline;
}

/* CART MODAL */
.cart-modal * {
    font-size: 15px;
}

.cart-modal .modal-table .table-head p {
    font-size: 1rem;
    /* font */
}

.gray-border-bottom {
    border-bottom: 2px solid gray;
}

.test-border {
    border: 1px solid red;
}

.btn-muted {
    padding: 0;
    background: none;
    outline: 1px solid transparent;
    border: none;
    cursor: pointer;
}